import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";

export const API_AUTOMATION = process.env?.REACT_APP_API_AUTOMATION;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_V2_URL = process.env.REACT_APP_API_V2_URL;
export const API_BETA_URL = process.env.REACT_APP_API_BETA_URL;
// process.env.REACT_APP_API_BETA_URL;
export const API_SANDBOX_URL = process.env.REACT_APP_API_SANDBOX_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const API_PUBLIC_URL = process.env.REACT_APP_API_PUBLIC_URL;
export const API_WAREHOUSE_V1_URL = process.env.REACT_APP_API_WAREHOUSE_V1_URL;
export const REACT_APP_DOMAIN_FILE = process.env.REACT_APP_DOMAIN_FILE;

export const API_V3_URL = process.env.REACT_APP_API_V3_URL;
export const NOTIFICATION_CHANNEL_URL =
  process.env.REACT_APP_NOTIFICATION_CHANNEL;
export const APP_URL = process.env.REACT_APP_URL;

export const API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;
export const BUSINESS_PRIVATE_TYPES =
  process.env.REACT_APP_BUSINESS_PRIVATE_TYPES;

export const EMPLOYEE_NATIONS = require("../libs/nations.json");
export const RELIGIONS = require("../libs/religions.json");
export const COUNTRIES = require("./contries");
export const APP_PACKAGE = require("../../package.json");
export const IDENTITIES = require("../libs/identity.json");
export const DICTIONARIES = require("../libs/translates.json");

export const INVOICE_MAILER_CC = [
  "cskh@vnetwork.vn",
  "accountant@vnetwork.vn",
];

export const CREDIT_TYPES = [
  { value: "", label: "Unknown", color: "blueGrey" },
  { value: "deposit", label: "Deposit", color: "success" },
  { value: "applied", label: "Applied", color: "pending" },
  { value: "refund", label: "Refund", color: "error" },
];

export const PRODUCT_STATUS = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Active" },
  { value: 3, label: "Suspended" },
  { value: 4, label: "Cancelled" },
  { value: 5, label: "Trial" },
  { value: 6, label: "Payment Pending" },
  { value: 7, label: "Install Service Pending" },
];

export const CUSTOMER_STATUS = [
  { value: 1, label: "Mới", chip: "primary" },
  { value: 2, label: "Tiềm Năng", chip: "pending" },
  { value: 3, label: "Đã Chốt", chip: "success" },
  { value: -1, label: "ERROR", chip: "error" },
];

export const THRESHOLD_BINDS = [
  { value: "waf::usage::threshold", label: "WAF Usage Threshold" },
  { value: "waf::elk::threshold", label: "WAF ELK Threshold" },
  {
    value: "brobe::origin::status::healthcheck",
    label: "Brobe Origin Status Healthcheck",
  },
  { value: "brobe::status::healthcheck", label: "Brobe Status Healthcheck" },
  {
    value: "vtvdigital::status::healthcheck",
    label: "vtvdigital::status::healthcheck",
  },
];

export const THRESHOLD_STATUS = [
  { value: "pending", label: "Pending" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "verified", label: "Verified" },
];

export const THRESHOLD_INTERVALS = [
  { value: "minutely-1m", label: "minutely-1m" },
  { value: "minutely-5m", label: "minutely-5m" },
  { value: "minutely-30m", label: "minutely-30m" },
  { value: "hourly-1h", label: "hourly-1h" },
  { value: "hourly-4h", label: "hourly-4h" },
  { value: "hourly-8h", label: "hourly-8h" },
  { value: "hourly-12h", label: "hourly-12h" },
  { value: "daily-1d", label: "daily-1d" },
];

export const TICKET_RECEIVE_SOURCE = [
  {
    label: "HOTLINE",
    value: "HOTLINE",
  },
  {
    label: "EMAIL",
    value: "EMAIL",
  },
  {
    label: "ZALO",
    value: "ZALO",
  },
  {
    label: "OTHERS",
    value: "OTHERS",
  },
  {
    label: "TECH SUPPORT",
    value: "TECH SUPPORT",
  },
  {
    label: "SKYPE",
    value: "SKYPE",
  },
  {
    label: "TELEGRAM",
    value: "TELEGRAM",
  },
  {
    label: "AUTOMATION",
    value: "AUTOMATION",
  },
];

export const TICKET_URGENCY = [
  {
    label: "Chưa phân loại",
    value: null,
    muiChip: { variant: "customize-error" },
  },
  {
    label: "LOW",
    value: 1,
    muiChip: { variant: "customize-pending" },
  },
  {
    label: "MEDIUM",
    value: 2,
    muiChip: { variant: "customize-primary" },
  },
  {
    label: "HIGH",
    value: 3,
    muiChip: { variant: "customize-error" },
  },
];

export const THRESHOLD_SCOPE = [
  { value: "default", label: "default" },
  { value: "request.ip.breakdown", label: "request.ip.breakdown" },
  { value: "OriginShield-RemoteAddress", label: "OriginShield-RemoteAddress" },
  { value: "request.status.breakdown", label: "request.status.breakdown" },
  { value: "waf::usage::healthcheck", label: "waf::usage::healthcheck" },
];

export const arrayNumberUnique = (arr) => {
  const hasMap = {};

  arr.forEach((item) => {
    hasMap[item] = null;
  });

  return Object.keys(hasMap)?.map((item) => Number(item));
};

export const THRESHOLD_BIND = [
  {
    value: "brobe::origin::status::healthcheck",
    label: "brobe::origin::status::healthcheck",
  },
  { value: "waf::elk::threshold", label: "waf::elk::threshold" },
  { value: "waf::usage::threshold", label: "waf::usage::threshold" },
  { value: "brobe::status::healthcheck", label: "brobe::status::healthcheck" },
  { value: "waf::usage::healthcheck", label: "waf::usage::healthcheck" },
];

export const SUBSCRIBER_SCOPE = [
  { value: "telegrambot", label: "Telegram" },
  { value: "skypebot", label: "Skype" },
  { value: "mailer", label: "Mailer" },
];

export const styleLineClamp = (numberOfLine) => {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: `${numberOfLine}`,
    WebkitBoxOrient: "vertical",
    fontSize: 14,
  };
};

export const CUSTOMER_STATUS_TYPES = [
  {
    value: "cool-lead",
    label: "Cool Lead",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "qualified-prospects",
    label: "Qualified Prospects",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: "active",
    label: "Active",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: "trial",
    label: "Trial",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: "suspended",
    label: "Suspended",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "stop",
    label: "Stop",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
];

export const INITIAL_WEBSITES = [
  {
    value: "datacentral",
    name: "datacentral",
    label: "DC - DataCentral",
  },
  {
    value: "campus",
    name: "campus",
    label: "CP - Elearning Campus",
  },
  {
    value: "portal",
    name: "portal",
    label: "P - Portal",
  },
];

export const INITIAL_ACTIONS = [
  {
    name: "list",
    label: "List -Xem danh sách",
  },
  {
    name: "create",
    label: "Create - Tạo mới/ Thêm mới",
  },
  {
    name: "detail",
    label: "Detail - Xem chi tiết",
  },
  {
    name: "update",
    label: "Update - Cập nhật",
  },
  {
    name: "destroy",
    label: "Destroy - Xoá bỏ/ Huỷ",
  },
];
export const INITIAL_FEATURES = [
  {
    name: "dashboard",
    label: "Dashboard - Trang chủ",
  },
  {
    name: "service",
    label: "Services - Dịch vụ",
  },
  {
    name: "invoice",
    label: "Invoices - Hoá đơn",
  },
  {
    name: "customer",
    label: "Customers - Khách hàng",
  },
  {
    name: "customer-spam",
    label: "Customer Spam - Khách hàng Spam",
  },
  {
    name: "employee",
    label: "Employee - Nhân viên",
  },
  {
    name: "contract",
    label: "Contracts - Hợp đồng",
  },
  {
    name: "partner-contract",
    label: "Partner Contracts - Hợp đồng đối tác",
  },
  {
    name: "sample-contract",
    label: "Sample Contracts - Hợp đồng mẫu",
  },
  {
    name: "contract-number",
    label: "Contract Numbers - Số hợp đồng",
  },
  {
    name: "postman-tracking",
    label: "Postman Tracking - Theo dõi thư",
  },
  {
    name: "warehouse",
    label: "Warehouses - Kho & Đầu Tư",
  },
  {
    name: "question",
    label: "Questions - Q&A",
  },
  {
    name: "report",
    label: "Reports - Báo cáo",
  },
  {
    name: "setting",
    label: "Settings - Cài đặt",
  },
  {
    name: "helper",
    label: "Helpers - Trợ giúp",
  },
  {
    name: "management",
    label: "Managements - Quản trị viên",
  },
  {
    name: "classroom",
    label: "Classrooms - Quản lý lớp học",
  },
  {
    name: "course",
    label: "Courses - Quản lý chương trình học",
  },
  {
    name: "examination",
    label: "Examinations - Quản lý bài thi",
  },
];

export const WAREHOUSE_PROCESS_TYPE_RADIOS = {
  form: {
    label: "Loại",
    name: "wp_type",
    explain: "",
  },
  options: [
    {
      value: "0",
      label: "Nhập",
      disable: false,
    },
    {
      value: "2",
      label: "Luân Chuyển",
      disable: false,
    },
    {
      value: "1",
      label: "Xuất",
      disable: false,
    },
    // {
    // 	value: "3",
    // 	label: "Hủy",
    // 	disable: false,
    // },
  ],
};

export const CAPACYTIES = [
  {
    key: "capacity_cpu",
    label: "CPU",
    unit: "CORE",
  },
  {
    key: "capacity_ram",
    label: "RAM",
    unit: "GB",
  },
  {
    key: "capacity_storage",
    label: "STORAGE",
    unit: "GB",
  },
  {
    key: "capacity_power_supply",
    label: "Power Supply",
    unit: "Kw",
  },
];

export const WAREHOUSE_STATUS_BUY = [
  { value: 1, label: "New" },
  { value: 2, label: "Like New" },
  { value: 3, label: "Hư" },
];

export const WAREHOUSE_ITEM_STOCK_STATUS = [
  { value: "true", label: "No" },
  { value: "false", label: "Yes" },
];

export const INITIAL_WAREHOUSE_BOX_TYPES = [
  {
    value: 0,
    label: "Kho",
  },
  {
    value: 1,
    label: "IDC",
  },
];

export const INITIAL_SIDEBARS = [
  {
    name: "dashboard",
    icon: "Dashboard",
    title: "Dashboard",
    path: "/",
    required: false,
  },
  {
    name: "service",
    icon: "Service",
    title: "Dịch vụ",
    path: "/services",
    required: true,
  },
  {
    name: "invoice",
    icon: "Invoice",
    title: "Hoá đơn",
    path: "/invoices",
    required: true,
  },
  {
    name: "contact",
    icon: "Marketing",
    title: "KH Marketing",
    required: true,
  },
  {
    name: "customer",
    icon: "Customer",
    title: "Khách hàng",
    required: true,
  },
  {
    name: "hrm",
    icon: "Employee",
    groupAccepts: ["hrm", "resume", "workdays"],
    title: "HRM",
    required: true,
  },
  {
    name: "contract",
    icon: "Contract",
    title: "Quản lý hợp đồng",
    required: true,
  },
  {
    name: "warehouse",
    icon: "Warehouse",
    title: "Kho & Đầu tư",
    path: "/warehouse",
    required: true,
  },
  {
    name: "question",
    icon: "Question",
    title: "Q&A",
    path: "/questions",
    required: true,
  },
  {
    name: "report",
    icon: "Report",
    title: "Báo cáo",
    required: true,
  },
  {
    name: "analytic",
    icon: "Analytic",
    title: "Thống kê",
    required: true,
  },
  {
    name: "files",
    groupAccepts: ["document"],
    icon: "File",
    title: "Files",
    required: true,
  },
  {
    name: "drives",
    icon: "Drives",
    title: "Data Central Drives",
    required: true,
    path: "/files/drives",
  },
  {
    name: "ticket",
    icon: "ConfirmationNumberIcon",
    title: "Quản lý ticket",
    path: "/tickets",
    required: true,
  },
  {
    name: "setting",
    icon: "Setting",
    groupAccepts: ["manage", "group-role", "permission"],
    title: "Quản Lý",
    required: true,
  },
  {
    name: "account",
    icon: "Account",
    title: "Cá nhân",
    path: "/account",
  },
  {
    name: "changelog",
    icon: "DiamondIcon",
    title: "Changelog",
    path: "/changelogs",
    required: true,
  },
  {
    name: "helper",
    icon: "Helper",
    title: "Trợ giúp",
    path: "/helpers",
    required: true,
  },
  // {
  //   name: "testtech",
  //   icon: "ReportWaf",
  //   title: "Quản lý REPORT WAF",
  //   path: "/technical/reports",
  //   required: true,
  // },
];
export const INITIAL_CONDITIONS = [
  {
    name: "$or",
    label: "Hoặc",
  },
  {
    name: "$and",
    label: "Và",
  },
];
export const INITIAL_CUSTOMER_FIELDS = [
  {
    field: "customer_name",
    label: "Tên Khách Hàng",
    type: "string",
  },
  {
    field: "customer_status",
    label: "Trạng Thái",
    type: "string",
  },
  {
    field: "customer_created_at",
    label: "Ngày Tạo",
    type: "date",
  },
  {
    field: "customer_name_note",
    label: "Người Ghi Chú",
    type: "string",
  },
  {
    field: "customer_email",
    label: "Email Khách Hàng",
    type: "string",
  },
];
export const WAREHOUSE_ITEM_STATUS = [
  { value: 0, label: "Mới", chip: "primary" },
  { value: 1, label: "Cũ", chip: "pending" },
  { value: 2, label: "Xuất", chip: "success" },
  { value: 3, label: "Hủy", chip: "error" },
  { value: 4, label: "Hư", chip: "blueGrey" },
];

export const INVOICE_STATUS_TYPES = [
  {
    value: 1,
    label: "Chưa thanh toán",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: 2,
    label: "Đã thanh toán",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 3,
    label: "Huỷ thanh toán",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
];

export const INVOICE_PAYMENT_METHODS = [
  { value: 0, label: "Bank Transfer" },
  { value: 1, label: "Credit" },
  { value: 2, label: "PayPal" },
  { value: 3, label: "MoMo" },
];

export const REPORT_DATE_TYPE = [
  // { value: "day", label: "Theo Ngày/Tháng" },
  { value: "month", label: "Theo Tháng/Năm" },
  { value: "quarter", label: "Theo Quý" },
  { value: "year", label: "Theo Năm" },
];

export const REPORT_OPTION_TYPE = [
  { value: 0, label: "Nhân Viên" },
  { value: 1, label: "Phòng Ban" },
];
export const WAREHOUSE_ITEM_UNIT = [
  { value: 0, label: "Cái" },
  { value: 1, label: "Con" },
  { value: 2, label: "Chiếc" },
  { value: 3, label: "Cuộn" },
  { value: 4, label: "Cọng" },
  { value: 5, label: "Hộp" },
  { value: 6, label: "Bộ" },
  { value: 7, label: "Sợi" },
];

export const WAREHOUSE_TYPE_UNIT = [
  { value: "CÁI", label: "CÁI" },
  { value: "CON", label: "CON" },
  { value: "CUỘN", label: "CUỘN" },
  { value: "THANH", label: "THANH" },
  { value: "CHIP", label: "CHIP" },
  { value: "HỘP", label: "HỘP" },
  { value: "BỘ", label: "BỘ" },
  { value: "SỢI", label: "SỢI" },
];

export const WAREHOUSE_ITEM_FACE = [
  { value: 0, label: "Full" },
  { value: 1, label: "Trước" },
  { value: 2, label: "Sau" },
];

export const WAREHOUSE_BOX_TYPE = [
  { value: 0, label: "KHO" },
  { value: 1, label: "IDC" },
];

export const WAREHOUSE_PROCESS_STATUS = [
  { value: 0, label: "Xử Lý", chip: "pending" },
  { value: 1, label: "Hoàn Thành", chip: "success" },
  { value: 2, label: "Hủy", chip: "error" },
];

export const WAREHOUSE_PROCESS_STATUS_TYPE = [
  { value: "pending", label: "Chờ Xử Lý" },
  { value: "completed", label: "Hoàn Thành" },
  { value: "cancelled", label: "Hủy" },
];

export const WAREHOUSE_PROCESS_TYPE = [
  { value: 0, label: "Nhập" },
  { value: 1, label: "Xuất Cho Mượn" },
  {
    value: 3,
    label: "Xuất Bán",
  },
  { value: 2, label: "Luân chuyển" },
  // { value: 3, label: "Hủy" },
];
export const INVOICE_STATUS = [
  { value: 0, label: "Draft", name: "Bản Nháp", chip: "blueGrey" },
  { value: 1, label: "Unpaid", name: "Chưa Thanh Toán", chip: "primary" },
  { value: 2, label: "Paid", name: "Đã Thanh Toán", chip: "success" },
  { value: 3, label: "Cancelled", name: "Đã Huỷ", chip: "error" },
  { value: 4, label: "Refunded", name: "Đã Hoàn Trả", chip: "lightBlue" },
  {
    value: 5,
    label: "Payment Pending",
    name: "Đang chờ thanh toán",
    chip: "pending",
  },
  { value: 10, label: "Merged", name: "Đã Gộp", chip: "pending" },
];
export const PRODUCT_UNITS = [
  { value: "qty", label: "Số Lượng" },
  { value: "Pack/ Month", label: "Tháng" },
  { value: "Pack/ Year", label: "Năm" },
  { value: "domain", label: "Domain" },
  { value: "hour", label: "Hour(s)" },
  { value: "hours", label: "Hour(s)" },
  { value: "bytes", label: "bytes" },
  { value: "kb", label: "kB" },
  { value: "mb", label: "MB" },
  { value: "gb", label: "GB" },
  { value: "tb", label: "TB" },
  { value: "pb", label: "PB" },
  { value: "mbps", label: "Mbps" },
  { value: "gbps", label: "Gbps" },
  { value: "tbps", label: "Tbps" },
  { value: "pbps", label: "Pbps" },
  { value: "package", label: "Gói" },
  { value: "milion-requests", label: "Milion Requests" },
  { value: "milion-queries", label: "Milion Queries" },
  { value: "queries", label: "Queries" },
  { value: "k-queries", label: "K Queries" },
  { value: "requests", label: "Requests" },
  { value: "k-requests", label: "K Requests" },
  { value: "key", label: "Key" },
  { value: "rules", label: "Rules" },
  { value: "fqnd", label: "FQND" },
  { value: "Pack/ Site", label: "Pack/ Site" },
  { value: "w", label: "Watt - Oát (W)" },
  { value: "core", label: "Core (s)" },
  { value: "u", label: "U" },
  { value: 'gbminutes', label: 'Gbpm (GbMinutes)' },
  { value: 'server', label: 'Cái/ Con' },
  { value: 'stream', label: 'Luồng' },
];
export const PRODUCT_TYPES = [
  { value: 1, label: "Shared Hosting" },
  { value: 2, label: "Virtual Server" },
  { value: 3, label: "Dedicated Server" },
  { value: 11, label: "Mail Cloud Security" },
  { value: 12, label: "Mail Server" },
  { value: 13, label: "Mail Business" },
  { value: 18, label: "Others" },
];
export const SERVICE_PERIODS = [
  { label: "Một Lần", value: "onetime", coefficient: 1 },
  { label: "Một Lần", value: "lifetime", coefficient: 1 },
  { label: "1 Tháng", value: "monthly", coefficient: 1 },
  { label: "2 Tháng", value: "bimonthly", coefficient: 2 },
  { label: "3 Tháng", value: "quarterly", coefficient: 3 },
  { label: "4 Tháng", value: "fourmonth", coefficient: 4 },
  { label: "5 Tháng", value: "fivemonth", coefficient: 5 },
  { label: "6 Tháng", value: "semiannually", coefficient: 6 },
  { label: "7 Tháng", value: "sepmonth", coefficient: 7 },
  { label: "8 Tháng", value: "octmonth", coefficient: 8 },
  { label: "9 Tháng", value: "novmonth", coefficient: 9 },
  { label: "10 Tháng", value: "decmonth", coefficient: 10 },
  { label: "11 Tháng", value: "elevenmonth", coefficient: 11 },
  { label: "Một Năm", value: "annually", coefficient: 12 },
  { label: "Hai Năm", value: "biennially", coefficient: 24 },
  { label: "Ba Năm", value: "triennially", coefficient: 36 },
];
export const PRODUCT_PAYMENT_PRICES = [
  { value: 0, label: "onetime" },
  { value: 1, label: "monthly" },
  { value: 2, label: "quarterly" },
  { value: 3, label: "semiannually" },
  { value: 4, label: "annually" },
  { value: 5, label: "biennially" },
  { value: 6, label: "triennially" },
];
export const CUSTOMER_SOURCES = [
  { value: "research", label: "Research" },
  { value: "event", label: "Sự Kiện" },
  { value: "contact", label: "Giới Thiệu" },
  { value: "form", label: "Form" },
  { value: "livechat", label: "Livechat" },
  // { value: 6, label: "Fanpage" },
  { value: "hotline", label: "Hotline" },
  { value: "self", label: "Self" },
  // { value: 9, label: "Linkedin" },
];

export const GENDERS = [
  { value: "male", label: "Nam" },
  { value: "female", label: "Nữ" },
  { value: "other", label: "Giới tính khác" },
  { value: -1, label: "Không xác định" },
];
export const SERVICES = [
  { value: "CDN", label: "CDN" },
  { value: "CONTENT DELIVERY NETWORK", label: "CONTENT DELIVERY NETWORK" },
  { value: "CLOUD SERVER", label: "CLOUD SERVER" },
  { value: "VPS", label: "VPS" },
  { value: "HOSTING", label: "HOSTING" },
  { value: "TRANSCODING", label: "TRANSCODING" },
  { value: "SECUMAIL", label: "SECUMAIL" },
  { value: "DOMAIN", label: "DOMAIN" },
  { value: "EMAIL", label: "EMAIL" },
  { value: "EMAIL-VPRO", label: "EMAIL VPRO" },
  { value: "COLOCATION", label: "COLOCATION" },
  { value: "WEBSITE", label: "WEBSITE" },
  { value: "STORAGE", label: "STORAGE" },
  { value: "SSL", label: "SSL" },
  { value: "SERVER", label: "SERVER" },
  { value: "Receive Guard", label: "Receive Guard" },
  { value: "LIVESTREAM", label: "LIVESTREAM" },
  { value: "VNIS", label: "VNIS" },
  { value: "LSDN", label: "LSDN" },
  { value: "OTHERS", label: "OTHERS" },
  { value: "BMS", label: "BMS" },
];

export const INDUSTRIES = [
  {
    value: "ecomerce",
    label: "Ecomerce",
    childrens: [
      {
        value: "tmdt",
        label: "Thương Mại Điện Tử(Tiki, Lazada, Shoppee, Sendo,...)",
      },
    ],
  },
  {
    value: "education",
    label: "Education",
    childrens: [
      {
        value: "education",
        label:
          "Giáo Dục(Trường Học, Elearning, Phần mềm quản lý trường học,...)",
      },
    ],
  },
  {
    value: "fintech",
    label: "Fintech",
    childrens: [
      { value: "payment", label: "Hệ Thống Thanh Toán" },
      { value: "p2plending", label: "P2P Lending" },
      { value: "investment", label: "Retail Investment & Wealth Management" },
    ],
  },
  {
    value: "cryptocurrentcy",
    label: "Cryptocurrentcy",
    childrens: [
      { value: "trading-platform", label: "Sàn Giao Dịch Điện Tử, Blockchain" },
    ],
  },
  {
    value: "logistics",
    label: "Logistics",
    childrens: [
      { value: "logistic", label: "Vận Chuyển, Giao Nhận, Express,..." },
    ],
  },
  {
    value: "multimedia",
    label: "Multimedia",
    childrens: [
      { value: "new", label: "Tin Tức" },
      { value: "ott", label: "OTT" },
      { value: "music", label: "Web/ App Âm Nhạc" },
      { value: "livestream", label: "LiveStream/ Video Stream" },
      { value: "advertisement", label: "Quảng Cáo" },
      { value: "social", label: "Mạng Xã Hội" },
      { value: "digital-marketing", label: "Digital Marketing" },
    ],
  },
  {
    value: "technology",
    label: "Technology",
    childrens: [
      { value: "service", label: "Dịch Vụ" },
      { value: "outsource", label: "Outsource" },
      { value: "software", label: "Phần mềm" },
    ],
  },
  {
    value: "game",
    label: "Game",
    childrens: [
      { value: "ntf-game", label: "Game NFT" },
      { value: "web-game", label: "Web/ App Game" },
    ],
  },
  {
    value: "real-estate",
    label: "Real Estate",
    childrens: [
      { value: "property-technology", label: "Property Technology" },
      { value: "bds", label: "BDS truyền thống" },
    ],
  },
  {
    value: "retail",
    label: "Retail",
    childrens: [
      { value: "retail", label: "Bán lẻ( Distributor, Wholesaler, Retailer)" },
    ],
  },
  {
    value: "orthers",
    label: "Orthers",
    childrens: [
      { value: "construction", label: "Construction" },
      { value: "manufacturing", label: "Manufacturing" },
      { value: "tourism", label: "Tourism" },
      { value: "healthcare", label: "Healthcare" },
      { value: "hospital", label: "Hospital" },
    ],
  },
  {
    value: "insurance",
    label: "Insurance",
    childrens: [{ value: "insurance", label: "Bảo hiểm có web/app" }],
  },
  {
    value: "securities",
    label: "Securities",
    childrens: [{ value: "stock", label: "Chứng Khoán" }],
  },
  {
    value: "airlines",
    label: "Airlines",
    childrens: [
      { value: "airline", label: "Hàng không" },
      { value: "airport", label: "Cảng hàng không" },
    ],
  },
  {
    value: "bank",
    label: "Bank",
    childrens: [{ value: "digital-banking", label: "Ngân hàng số" }],
  },
  {
    value: "telecom",
    label: "Telecom",
    childrens: [
      { value: "telecom", label: "Viễn Thông" },
      { value: "isp", label: "ISP" },
      { value: "saas", label: "SaaS" },
    ],
  },
  {
    value: "goverment",
    label: "Goverment",
    childrens: [{ value: "organic-goverment", label: "Các cơ quan nhà nước" }],
  },
];

export const INITIAL_OPERATORS = [
  { operator: "$btw", name: "from ... to", label: "Trong khoảng", show: "€" },
  { operator: "$in", name: "is any of", label: "Là bất kỳ của", show: "[]" },
  { operator: "$nin", name: "is not any of", label: "Không thuộc", show: "[]" },
  { operator: "$included", name: "is any of", label: "Có", show: "[]" },
  { operator: "$eq", name: "equal to", label: "Bằng với", show: "=" },
  {
    operator: "$contains",
    name: "contains",
    label: "Bao gồm",
    show: "bao gồm",
  },
  {
    operator: "$ne",
    name: "not equal to",
    label: "Không bằng với",
    show: "!=",
  },
  { operator: "$gt", name: "greater than", label: "Lớn hơn", show: ">" },
  {
    operator: "$gte",
    name: "greater than or equal to",
    label: "Lớn hơn hoặc bằng",
    show: ">=",
  },
  { operator: "$lt", name: "less than", label: "Nhỏ hơn", show: "<" },
  {
    operator: "$lte",
    name: "less than or equal to",
    label: "Nhỏ hơn hoặc bằng",
    show: "<=",
  },
  {
    operator: "$notContains",
    name: "not contains",
    label: "Không bao gồm",
    show: "không bao gồm",
  },
  {
    operator: "$json",
    name: "same",
    label: "Same",
    show: "Same",
  },
];

export const DOMAIN_PERIOD = [
  { value: "annually", label: "1 năm" },
  { value: "biennially", label: "2 năm" },
  { value: "triennially", label: "3 năm" },
  { value: "quadrennial", label: "4 năm" },
  { value: "quinquennial", label: "5 năm" },
  { value: "sexennial", label: "6 năm" },
  { value: "septennial", label: "7 năm" },
  { value: "octennial", label: "8 năm" },
  { value: "novennial", label: "9 năm" },
  { value: "decennial", label: "10 năm" },
];

export const DNS_RECORD_TYPE = [
  { value: "A", label: "A" },
  { value: "AAAA", label: "AAAA" },
  { value: "MX", label: "MX" },
  { value: "CNAME", label: "CNAME" },
  { value: "TXT", label: "TXT" },
];
export const CUSTOMER_JOURNEYS = [
  { value: "searching", label: "Searching - Tìm kiếm" },
  { value: "contact", label: "Contact - Kết nối, liên hệ, gặp mặt" },
  {
    value: "presentation",
    label:
      "Presentation -  Meeting chính thức giữa 2 bên,  Tạo nhóm trao đổi sâu về sản phẩm / giải pháp",
  },
  { value: "poc", label: "PoC - Dùng thử" },
  { value: "deal", label: "Deal - Thương lượng" },
  { value: "contract", label: "Contract - Ký hợp đồng" },
  { value: "closed", label: "Closed - Kết thúc quá trình" },
  // { value: 'retension', label: "Retension - Gia hạn" },
  { value: "upsale", label: "Upsales - Bán thêm" },
];
export const CUSTOMER_JOURNEY_STATUS = [
  {
    value: "processing",
    label: "Đang Thực Hiện",
    textColor: "#4C57A9",
    bgcolor: "#4C57A91F",
  },
  {
    value: "completed",
    label: "Đã Hoàn Tất",
    textColor: "#66BB6A",
    bgcolor: "#66BB6A1F",
  },
  {
    value: "pending",
    label: "Pending/ Dừng",
    textColor: "#EF5350",
    bgcolor: "#EF53501F",
  },
  { value: "skip", label: "Skip", textColor: "#777680", bgcolor: "#7776801F" },
];
export const WAREHOUSE_TYPE_TYPES = [
  { value: 0, label: "Thiết Bị", color: "primary" },
  { value: 1, label: "Linh kiện", color: "success" },
  { value: 2, label: "LK & PK Theo Mã Hàng", color: "pending" },
];

export const WAREHOUSE_BOX_RACK_SIZES = [
  { value: 36, label: "36 U" },
  { value: 42, label: "42 U" },
  { value: 48, label: "48 U" },
  { value: 52, label: "52 U" },
];

export const EMPLOYEE_DISABLED_STATUS = [
  { value: true, label: "Đã Nghỉ Việc", color: "error" },
  { value: false, label: "Đang Hoạt Động", color: "success" },
];

export const EMPLOYEE_TYPES = [
  { value: 0, label: "Cộng Tác Viên" },
  { value: 1, label: "Thực Tập Sinh" },
  { value: 2, label: "Thử Việc" },
  { value: 3, label: "Chính Thức" },
  { value: 4, label: "Chính Thức Trên 5 Năm" },
  { value: 5, label: "BOD" },
];
export const EMPLOYEE_BANKING_NAME_ACCEPTS = [
  { value: "vietcombank", label: "NH TMCP Ngoại Thương Việt Nam(Vietcombank)" },
  { value: "techcombank", label: "NH TMCP Kỹ Thương(Techcombank)" },
  { value: "orther", label: "Ngân hàng khác" },
];

export const DAYOFF_TYPE_TYPES = [
  { value: 0, label: "Nghỉ Không Lương" },
  { value: 1, label: "Nghỉ Phép Năm" },
];

export const DAYOFF_TIME_OPTIONS = [
  { value: 0, label: "Buổi Sáng" },
  { value: 1, label: "Buổi Chiều" },
  { value: 2, label: "Cả Ngày" },
];

export const DAYOFF_STATUS_TYPES = [
  { value: 3, label: "Không Duyệt", chip: "blueGrey" },
  { value: 2, label: "Đã Duyệt", chip: "success" },
  { value: 1, label: "Phiếu Huỷ", chip: "error" },
  { value: 0, label: "Chờ Duyệt", chip: "pending" },
  { value: 4, label: "Leader Đã Duyệt", chip: "success" },
];
export const BUSINESS_STATUS_TYPES = [
  { value: 3, label: "Không Duyệt", chip: "blueGrey" },
  { value: 1, label: "Đã Duyệt", chip: "success" },
  { value: 2, label: "Phiếu Huỷ", chip: "error" },
  { value: 0, label: "Chờ Xử Lý", chip: "pending" },
];

export const DAYOFF_STATUS_CONFIRM_TYPES = [
  { value: false, label: "Chờ Xử Lý", color: "pending" },
  { value: true, label: "Duyệt", color: "success" },
];

export const CONTRACT_NUMBER_STATUS_TYPES = [
  {
    value: 1,
    label: "Mới Tạo",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: 2,
    label: "Đã Huỷ",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 3,
    label: "Trao Đổi",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: 4,
    label: "In & Trình Ký",
    muiTypography: { color: "custom.lightBlue" },
    muiChip: { variant: "customize-lightBlue" },
  },
  // {
  //   value: 5,
  //   label: "Xử Lý Thư",
  //   muiTypography: { color: "custom.pending" },
  //   muiChip: { variant: "customize-pending" },
  // },
  {
    value: 6,
    label: "Upload HD",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: 0,
    label: "Hoàn Tất",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 8,
    label: "Hoàn Tất",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 9,
    label: "Đã Đóng",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
];

export const CONTRACTS_STATUS = [
  {
    value: 0,
    label: "ACTIVE",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 1,
    label: "Mới Tạo",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: 2,
    label: "CANCEL",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 3,
    label: "Trao Đổi",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: 4,
    label: "PENDING",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: 6,
    label: "Upload HD",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: 8,
    label: "ACTIVE",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
];

export const CONTRACT_PROCESS_STATUS = [
  { value: 0, label: "Chờ Xử Lý", chip: "pending" },
  { value: 1, label: "Hoàn Tất", chip: "success" },
  { value: 2, label: "Đã Huỷ", chip: "error" },
];

export const ATTACHMENT_ICONS = {
  PDF: PictureAsPdfOutlinedIcon,
  IMAGE: ImageOutlinedIcon,
  CSV: GridOnOutlinedIcon,
  VIDEO: VideocamOutlinedIcon,
  TXT: ArticleOutlinedIcon,
  PPTX: DonutSmallOutlinedIcon,
};

export const POSTMAN_TRACKING_SCOPES = [
  { value: 0, label: "Gửi" },
  { value: 1, label: "Nhận" },
];

export const POSTMAN_TRACKING_TYPES = [
  { value: 0, label: "Hợp Đồng/ Phụ Lục" },
  { value: 1, label: "Công Văn" },
  { value: 2, label: "Khác" },
];

export const POSTMAN_TRACKING_PROVIDERS = [
  { value: 0, label: "24/7 Express" },
  { value: 1, label: "Grab Express" },
  { value: 2, label: "Viettel Post" },
  { value: 3, label: "VTG Express" },
  { value: 4, label: "Khác" },
  { value: 5, label: "Không Có" },
];

export const POSTMAN_TRACKING_STATUS = [
  { value: 0, label: "Đang Gửi Thư", chip: "pending" },
  { value: 1, label: "Đối tác Đã Nhận HĐ", chip: "success" },
  { value: 2, label: "VNETWORK Đã Nhận HĐ", chip: "success" },
  { value: 3, label: "Hủy", chip: "error" },
];

export const CONTRACT_PROCESS_STATUS_TYPES = [
  { value: 0, label: "Chờ Duyệt", chip: "pending" },
  { value: 1, label: "Đã Duyệt", chip: "success" },
  { value: 2, label: "Huỷ", chip: "error" },
];

export const WAREHOUSE_RESOURCE_STATUS = [
  { value: 0, label: "Kích Hoạt", chip: "success" },
  { value: 1, label: "Chưa kích Hoạt", chip: "pending" },
];
export const WAREHOUSE_RESOURCE_TYPES = [
  { value: 0, label: "Dedicate" },
  { value: 1, label: "Virturalization" },
];

export const TRANSACTION_STATUS = [
  { value: 0, label: "Chưa Thanh Toán", chip: "error" },
  { value: 1, label: "Đã Thanh Toán", chip: "success" },
];

export const INVESTMENT_RESOURCE_STATUS = [
  { value: true, label: "Hoạt Động", chip: "success" },
  { value: false, label: "Hết Hạn Đầu Tư", chip: "error" },
];

export const WAREHOUSE_RESOURCE_ITEM_STATUS = [
  { value: 0, label: "Kích Hoạt", chip: "success" },
  { value: 1, label: "Đã Huỷ", chip: "error" },
];
export const CONTRACT_STATUS = [
  { value: 0, label: "Kích Hoạt", chip: "success" },
  { value: 1, label: "Đã Huỷ", chip: "error" },
];

export const PREFIX_OF_ATTACHMENT = {
  contract: "contracts/documents",
  contractNumber: "contractNumbers/contractProcesses/documents",
  customerJourney: "customers/customerJourneys/documents",
  ticket: "tickets",
  document: "documents",
};

export const PAYMENT_CONFIGS = [
  { value: "prevpaid-crosscheck", label: "Theo gói cam kết & đối soát vượt" },
  { value: "prevpaid-free", label: "Trả trước, miễn phí vượt" },
  { value: "prevpaid-depreciation", label: "Trả trước, khấu hao gói" },
  { value: "postpaid", label: "Trả sau theo lưu lượng sử dụng" },
];

export const UNLIMITED = 999999999;

export const CALCULATION_METHODS = [
  { value: "plus", label: "Cộng", icon: "+" },
  { value: "minus", label: "Trừ", icon: "-" },
  { value: "multiplication", label: "Nhân", icon: "*" },
  { value: "divide", label: "Chia", icon: "/" },
];

export const OPERATOR_METHODS = [
  { value: "$eq", label: "Bằng với", icon: "=" },
  { value: "$ne", label: "Không bằng với", icon: "!=" },
  { value: "$gt", label: "Lớn hơn", icon: ">" },
  { value: "$gte", label: "Lớn hơn hoặc bằng", icon: ">=" },
  { value: "$lt", label: "Nhỏ hơn", icon: "<" },
  { value: "$lte", label: "Nhỏ hơn hoặc bằng", icon: "<=" },
];

export const OPTIONAL_CONDITION_KEYS = [
  { value: "usage", label: "Thông số sử dụng" },
  { value: "pricing", label: "Đơn giá" },
  { value: "total", label: "Tổng giá trị đơn hàng" },
];

export const SERVICE_TYPES = [
  { value: "ConversantService", label: "ConversantService" },
  { value: "CloudService", label: "CloudService" },
  { value: "DomainService", label: "DomainService" },
  { value: "HostingService", label: "HostingService" },
];

export const SERVICE_STATUS_TYPES = [
  {
    value: "pending",
    label: "Suppend",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "active",
    label: "Active",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: "progressive",
    label: "Suppend",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "cancel",
    label: "Stop",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: "trial",
    label: "Trial",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: "payment-pending",
    label: "Suppend",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "waiting-create-service",
    label: "Suppend",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: "waiting-confirm",
    label: "Chờ CS Xác Nhận",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
];
export const BUSINESS_TYPES = [
  { value: "meeting", label: "Gặp khách hàng" },
  { value: "datacentral", label: "DataCenter" },
  { value: "others", label: "Khác" },
];

export const CONFIRM_STATUS = [
  {
    value: false,
    label: "Chờ Xử Lý",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: true,
    label: "Đã Duyệt",
    muiTypography: { color: "custom." },
    muiChip: { variant: "customize-success" },
  },
];

export const BUSINESS_STATUS = [
  {
    value: 3,
    label: "Không Duyệt",
    muiTypography: { color: "custom.grey" },
    muiChip: { variant: "customize-blueGrey" },
  },
  {
    value: 1,
    label: "Đã Duyệt",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 2,
    label: "Phiếu Huỷ",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 0,
    label: "Chờ Xử Lý",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
];

export const WORK_CONTRACT_STATUS = [
  {
    value: 0,
    label: "Hết Hiệu Lực",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 1,
    label: "Còn Hiệu Lực",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 3,
    label: "Đã Bị Huỷ",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
];

export const PRODUCT_COG_PAYMENT_TYPES = [
  { value: "default", label: "Mặc Định" },
  { value: "progressive", label: "Luỹ Tiến" },
  { value: "tierd", label: "Bậc" },
];

export const DEFAULT_VAT = 0.1; // 10%

export const SCHEDULE_TYPES = [
  { value: "interview", label: "Interview" },
  { value: "meeting-customer", label: "Meeting Customer" },
  { value: "meeting-team", label: "Meeting Team" },
  { value: "payroll", label: "Payroll" },
  { value: "self-activity", label: "Self-Activity" },
  { value: "company-activity", label: "Company-Activity" },
  { value: "company-meeting", label: "Company-Meeting" },
];

export const PRODUCT_PRICING_METHODS = [
  { value: "default", label: "Đơn Giá * SL" },
  { value: "progressive", label: "Luỹ Tiến" },
  { value: "tierd", label: "Bậc" },
];
export const PRODUCT_TYPE = [
  { value: "customized", label: "Tùy Chỉnh" },
  { value: "package", label: "Theo Gói" },
  { value: "cdn", label: "CDN" },
];

export const INITIALIZE_DOMAIN_FORM = {
  domain_type: "register",
  domain_value: "",
  domain_registrationperiod: "annually",
  domain_eppcode: "",
  dns_option: "default",
  addons: [
    {
      name: "domain_dns_management",
      label: "DNS Management",
      active: false,
    },
    {
      name: "domain_email_forwarding",
      label: "Email Forwarding",
      active: false,
    },
    {
      name: "domain_id_protection",
      label: "ID Protection",
      active: false,
    },
  ],
  domain_extra_fields: {
    dns: [{ name: "" }, { name: "" }, { name: "" }],
    for: "canhan",
    ownerName: "",
    ownerTaxCode: "",
    ownerID_Number: "",
    ownerAddress: "",
    ownerWard: "",
    ownerDistrict: { value: "10", label: "Quận 1" },
    ownerProvince: { value: "SG", label: "Hồ Chí Minh" },
    ownerCountry: { value: "VN", label: "Vietnam" },
    ownerEmail1: "",
    ownerEmail2: "",
    ownerPhone: "",
    ownerFax: "",
    uiName: "",
    uiTaxCode: "",
    uiID_Number: "",
    uiAddress: "",
    uiWard: "",
    uiDistrict: { value: "10", label: "Quận 1" },
    uiProvince: { value: "SG", label: "Hồ Chí Minh" },
    uiCountry: { value: "VN", label: "Vietnam" },
    uiEmail: "",
    uiPhone: "",
    uiGender: "",
    uiFax: "",
    uiBirthdate: "",
    uiCompany: "",
    uiPosition: "",
    adminName: "",
    adminID_Number: "",
    adminAddress: "",
    adminWard: "",
    adminDistrict: { value: "10", label: "Quận 1" },
    adminProvince: { value: "SG", label: "Hồ Chí Minh" },
    adminCountry: { value: "VN", label: "Vietnam" },
    adminEmail: "",
    adminPhone: "",
    adminGender: "",
    adminFax: "",
    adminBirthdate: "",
    adminCompany: "",
    adminPosition: "",
    sendmail: false,
  },
};
export const SERVICE_METADATA = {
  product_group_id: -1,
  product_group_name: "",
  product_id: -1,
  product_name: "",
  product_billing_cycle: "",
  product_payment_type: "prevpaid-crosscheck",
  product_template: "Normally",
  product_type: "",
  product_pricing_amount: 0, // Giá bán sản phẩm/ dịch vụ
  product_v_pricing: 0, // Giá niêm yết của công ty
  product_informations: [],
  product_configoptions: [],
  product_billing_configures: [],
  product_pricing_configures: {
    product_pricing_unit: "gb",
    product_pricing_method: "default",
    pricing: 0,
    pricing_apply: 0,
    pricings: [{ start: 0, end: 0, pricing: 0, pricing_apply: 0 }],
  },
  product_commitment: 0,
  product_addons: [],
  product_export_contract: true,
  domain: {
    ...INITIALIZE_DOMAIN_FORM,
  },
  service_extra_fields: {
    domain: "",
    ip: "",
    round: false,
  },
  product_addon_mergebill: false,
  service_management_billing: {
    service_billing_startdate: Date.now(),
    service_billing_enddate: Date.now(),
    service_nextbill_startdate: Date.now(),
    service_nextbill_enddate: Date.now(),
    service_control_startdate: Date.now(),
    service_control_enddate: Date.now(),
  },
  product_paid: true,
};

export const SALEPLAN_STATUS = [
  {
    value: 0,
    label: "---",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 4,
    label: "Đã Huỷ",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: 3,
    label: "Đã Bán Thành Công",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: 1,
    label: "Đang Hoạt Động",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: 2,
    label: "---",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
];

export const MEETING_STATUS = [
  {
    value: "N/A",
    label: "Đang thực hiện",
    muiTypography: { color: "custom.primary" },
    muiChip: { variant: "customize-primary" },
  },
  {
    value: "followup",
    label: "Tiềm năng",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: "closed",
    label: "Thất bại",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: "other",
    label: "Khác",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
];

export const CUSTOMER_REGISTER_TYPES = [
  { value: "individual", label: "Khách Hàng Cá Nhân" },
  { value: "company", label: "Khách Hàng Doanh Nghiệp" },
];

export const MARRIGAGE_TYPES = [
  { value: "alone", label: "Độc thân" },
  { value: "married", label: "Đã lập gia đình" },
  { value: -1, label: "Không xác định" },
];

export const WEEK_DAYS = [
  { value: 1, label: "T2", color: "#9e9e9e" },
  { value: 2, label: "T3", color: "#66bb6a" },
  { value: 3, label: "T4", color: "#FFA726" },
  { value: 4, label: "T5", color: "#ab47bc" },
  { value: 5, label: "T6", color: "#42a5f5" },
  { value: 6, label: "T7", color: "#ef5350" },
  { value: 7, label: "CN", color: "#78909c" },
];

export const CONTACT_STATUS = [
  { value: "UNVERIFIED", label: "UNVERIFIED" },
  { value: "VERIFIED", label: "VERIFIED" },
  { value: "SPAM", label: "SPAM" },
  { value: "DUPLICATED", label: "DUPLICATED" },
];
export const CONTACT_CHANNEL = [
  { value: "LIVE_CHAT", label: "Live Chat" },
  { value: "FB_MESSENGER", label: "Fanpage Chat" },
  { value: "FORMS", label: "Contact Form" },
  { value: "HOTLINE", label: "Hotline" },
  { value: "EVENT", label: "Event" },
  { value: "MARKETING_APPROACH", label: "Marketing Approach" },
];

export const CONTACT_STATUS_TYPES = [
  {
    value: "MATCHED",
    label: "Đúng đối tượng",
    muiTypography: { color: "custom.success" },
    muiChip: { variant: "customize-success" },
  },
  {
    value: "UN-MATCHED",
    label: "Không đúng đối tượng",
    muiTypography: { color: "custom.pending" },
    muiChip: { variant: "customize-pending" },
  },
  {
    value: "SPAM",
    label: "SPAM",
    muiTypography: { color: "custom.error" },
    muiChip: { variant: "customize-error" },
  },
  {
    value: "",
    label: "Chưa phân loại",
    muiTypography: { color: "custom.blueGrey" },
    muiChip: { variant: "customize-blueGrey" },
  },
];
export const INITIALIZE_SERVICE_GROUPS = [
  {
    value: [132, 133]?.join(","),
    label: "Domain",
  },
  {
    value: [...Array.from({ length: 21 }).map((__, i) => i + 108)]?.join(","),
    label: "SSL",
  },
  {
    value: [
      ...Array.from({ length: 8 }).map((__, i) => i + 63),
      ...[89, 90, 91, 92, 93, 94],
    ]?.join(","),
    label: "Email Server",
  },
  {
    value: [...[71, 72, 73, 74, 75, 76, 77, 78, 105]]?.join(","),
    label: "Email V Pro",
  },
  {
    value: [...[80, 81, 82, 83]]?.join(","),
    label: "Email V Business",
  },
  {
    value: [...[84, 85]]?.join(","),
    label: "Email V Premium",
  },
  {
    value: [...[86, 87, 88, 149, 321, 322, 323]]?.join(","),
    label: "EG",
  },
  {
    value: [
      ...Array.from({ length: 27 }).map((__, i) => i + 1),
      ...[358, 359, 360, 361, 371, 372],
      ...[95, 96, 97, 98, 99, 129, 130],
    ]?.join(","),
    label: "Hosting",
  },
  {
    value: [305, 306, 307, 326, 334, 335, 336, 370]?.join(","),
    label:
      "Nhóm dịch vụ CDN (CDN, Transcoding, Transmuxing, Recording, Storage,...)",
  },
  {
    value: [101, 102, 103, 104, 131, 308, 309, 310]?.join(","),
    label: "VNIS",
  },
  {
    value: [106, 315, 316]?.join(","),
    label: "Colocation",
  },
  {
    value: [
      141, 141, 143, 144, 145, 146, 147, 170, 171, 172, 173, 174, 175, 176,
    ]?.join(","),
    label: "Bare Metal Server",
  },
  {
    value: [28, 29, 30, 31, 32, 33, 79]?.join(","),
    label: "Cloud Server",
  },
  {
    value: [34, 35, 36, 37, 107]?.join(","),
    label: "Dedicated Server",
  },
];

export const format_pricing_vnd = (value) => {
  if (value === null || typeof value === "undefined") return "";

  return value.toLocaleString("vi", {
    style: "currency",
    currency: "VND",
    maxmumFractionDigits: 3,
  });
};

export const WAREHOUSE_RACK_SIZE_U = [
  { value: 36, label: "36 U" },
  { value: 42, label: "42 U" },
  { value: 48, label: "48 U" },
  { value: 52, label: "52 U" },
];

export const WAREHOUSE_RACK_ACTIVE_TYPES = [
  { value: "true", label: "Đang sử dụng" },
  { value: "false", label: "Trả Rack" },
];

export const API_CUSTOMER_URL = process.env.REACT_APP_API_CUSTOMER_URL;
export const API_STORAGE_URL = process.env.REACT_APP_DOMAIN_FILE;
export const API_REPORT_DYNAMIC = process.env.REACT_APP_API_REPORT_DYNAMIC_URL;

export const RANGE_MONTHS = Array.from({ length: 12 }, (__, i) => i + 1).map((value) => ({ label: value, value }))

export const PRIORITIES = [
  { value: 'LOW', label: 'Low' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'HIGH', label: 'High' },
]

export const COMMITMENT_PAYMENTS = [
  { value: true, label: 'Trả Trước' },
  { value: false, label: 'Trả Sau' },
]