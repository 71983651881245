import { encodeBase64 } from "@/functions";
import useOrders, { Order } from "@/hooks/useOrders";
import { Box, LinearProgress, Stack } from "@mui/material";
import { DataGridPro as MuiDataGrid, GridOverlay } from "@mui/x-data-grid-pro";
import React from "react";
import { useLocation } from "react-router-dom";

export { useDemoData as useSampleData } from "@mui/x-data-grid-generator";
export const { default: Toolbar } = require("./Toolbar");
export const { default: Pagination } = require("./Pagination");

export const LoadingOverlay = () => {
  return (
    <GridOverlay>
      <Box style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
};
const DataGridCustomize = ({
  rows = [],
  columns = [],
  loading = false,
  components = {
    Toolbar: Toolbar,
    Pagination: Pagination,
  },
  componentsProps = {},
  paginationMode = "server",
  ...props
}) => {

  const location = useLocation();
  const [rowCountState, setRowCountState] = React.useState(props?.rowCount);
  React.useEffect(() => {
    setRowCountState(() =>
      props?.rowCount !== undefined ? props?.rowCount : 0
    );
  }, [props?.rowCount]);
  // eslint-disable-next-line
  const [_, updateSort] = useOrders(props?.slots?.sorters?.key, location);

  const handleOrder = (hash) => {
    const sort = Object.assign({});
    sort[Object.keys(sort).length] = `${hash["field"]}:${hash["sort"]}`;
    localStorage.setItem(
      props?.slots?.sorters?.key,
      encodeBase64(Order.stringify(sort))
    );
    return [updateSort(sort), props?.slots?.sorters.callback(sort)];
  };

  return (
    <Box
      sx={{
        ".MuiTablePagination-selectLabel , .MuiTablePagination-displayedRows": {
          mt: 2,
        },
        ".MuiDataGrid-overlayWrapper": {
          top: "50%",
        },
        width: "100%",
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          height: "5px",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
          background: "#f0f0f0",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
          backgroundColor: "#000333",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
          background: "#000333",
        },
      }}
    >
      <MuiDataGrid
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        initialState={{ pinnedColumns: { right: ['actions', 'action'] } }}
        autoHeight
        {...props}
        columns={columns || []}
        rows={rows || []}
        loading={loading || props?.isFetching}
        size="small"
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Không tìm thấy dữ liệu.
            </Stack>
          ),
          LoadingOverlay: LoadingOverlay,
          Toolbar: components?.Toolbar === "disabled"
            ? null
            : components?.Toolbar || Toolbar,
          Pagination:
            components?.Pagination === "disabled" ? null : (components?.Pagination || Pagination),
        }}
        componentsProps={{
          pagination: {
            ...componentsProps?.pagination,
            page: componentsProps?.pagination?.page || 0,
            pageSize: componentsProps?.pagination?.pageSize || 0,
            rowCount: rowCountState || 0,
          },
          toolbar: componentsProps?.toolbar,
        }}
        hideFooter={components?.Pagination === "disabled" || !props?.rowCount}
        hideFooterPagination={components?.Pagination === "disabled" || !props?.rowCount}
        paginationMode={!props?.rowCount ? "client" : paginationMode}
        onSortModelChange={(model, _) => {
          if (paginationMode === "client") {
            return null;
          }
          return handleOrder(model?.[0]);
        }}
        sortingOrder={["asc", "desc"]}
        pagination={components?.Pagination === "disabled" ? false : true}
        sortingMode={paginationMode}
      />
    </Box>
  );
};
export default DataGridCustomize;
