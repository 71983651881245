import PopperAction from '@components/PopperAction'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import { Box, IconButton, Popper } from '@mui/material'
import { useMemo, useState } from 'react'
import ServiceActionUI from './ServiceActionUI'

const INITIAL_SERVICE_ACTIONS = [
  { icon: "Date", name: "service-start-date", label: "Ngày Bắt Đầu Dịch Vụ" },
  { icon: "MoreTime", name: "service-current-billing", label: "Thời Gian Thanh Toán Hiện Tại" },
  { icon: "MoreTime", name: "service-next-billing", label: "Thời Gian Gia Hạn" },
  { icon: "Price", name: "service-price-amount", label: "Thay Đổi Giá Bán Mới" },
  { icon: "Price", name: "service-price-recurring", label: "Thay Đổi Giá Gia Hạn" },
  { icon: "Statement", name: "service-date-statement", label: "Thời Gian Đối Soát" },
  { icon: "Diverity", name: "service-commissions", label: "Thay Đổi Người Hưởng Hoa Hồng" },
  { icon: "DateEvent", name: "service-date-renew", label: "Đặt Ngày Sinh Hoá Đơn Gia Hạn" },
  { icon: "Receipt", name: "service-create-invoice-renew", label: "Tạo Hoá Đơn Gia Hạn" },
  { icon: "UnLock", name: "service-active", label: "Kích Hoạt Dịch Vụ" },
  { icon: "Stop", name: "service-cancel", label: "Stop/ Huỷ dịch vụ" },
  { icon: "Setting", name: "service-information", label: "Chỉnh sửa cấu hình" },
  { icon: "Setting", name: "service-configoptions", label: "Chỉnh sửa cấu hình" },
  { icon: "Verified", name: "service-confirm", label: "Mở dịch vụ (Confirm)" },
  { icon: "Note", name: "service-note", label: "Thêm Ghi Chú" },
]

const ServiceActtions = ({ actions = [], service = {}, statementable = false }) => {
  const [popper, setPopper] = useState({ anchorEl: undefined, data: undefined, actions: [] });
  const [action, setAction] = useState({ open: false, name: undefined, data: undefined });

  const onClosePopper = () => setPopper((prev) => ({ ...prev, anchorEl: undefined, data: undefined, actions: [] }));
  const onCloseAction = () => setAction({ open: false, name: undefined, data: undefined });


  const rebuildActions = useMemo(() => {
    let actionBuilders = INITIAL_SERVICE_ACTIONS?.filter((item) => actions?.includes(item?.name))?.map((item) => {
      return ({
        ...item,
        handleClick: (row) =>
          setAction({
            open: true,
            name: item?.name,
            data: row,
          }),
      })
    })
    if (!!service?.service_create_is_trial) {  // TRIAL | POC
      return actionBuilders?.filter((item) =>
        ['service-cancel', 'service-information', 'service-note'].includes(item?.name)
      )
    }
    if (service?.service_status === 'waiting-confirm') {  // SALE | CS
      actionBuilders = actionBuilders?.filter((item) =>
        [
          'service-confirm',
          'service-information',
          'service-configoptions',
          'service-commissions',
          'service-current-billing',
          'service-price-amount'
        ].includes(item?.name))
    }
    if (service?.service_status === 'active') {  // SALE | CS
      actionBuilders = actionBuilders?.filter((item) => !['service-active', 'service-confirm'].includes(item?.name))
    }
    if (service?.service_status === 'cancel') { actionBuilders = [] }
    if (!statementable) {
      actionBuilders = actionBuilders?.filter((item) => !['service-date-statement'].includes(item?.name))
    }
    return actionBuilders
  }, [actions, service, statementable])

  const renderComponents = (ui = '') => {
    if (!ui) return null;
    const Component = ServiceActionUI[ui]
    if (!Component) return null
    const componentProps = {
      serviceId: service?.service_id,
      service,
      onClose: onCloseAction
    }

    return <Component {...componentProps} />
  }
  if (!rebuildActions || rebuildActions?.length === 0) return null

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {action?.open && renderComponents(action?.name)}
      {popper?.anchorEl !== undefined && (
        <Popper
          anchorEl={popper?.anchorEl}
          sx={{
            zIndex: 99,
          }}
          open={Boolean(popper.anchorEl)}
          onMouseLeave={onClosePopper}
        >
          <PopperAction {...popper} handleClose={onCloseAction} />
        </Popper>
      )}
      <IconButton
        size="small"
        onClick={(event) => {
          setPopper((prev) => ({
            ...prev,
            anchorEl: event?.currentTarget,
            data: service,
            actions: rebuildActions,
          }));
        }}
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default ServiceActtions