import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { useState } from "react";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import useDebounce from "@/hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import _ from "lodash";
import { useInfiniteQuery } from "react-query";
import { InView } from "react-intersection-observer";

const SearchDevice = ({
  size = 3,
  maxHeight = 680,
  onChange,
  paramsSearch = {},
  haveSearchWhenKeyWordEmpty = false,
  device = true,
  limit = 10,
  warehosue_process_tranfer = false
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();
  const debouncedValue = useDebounce(searchQuery, 500);

  const navigate = useNavigate();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [debouncedValue, paramsSearch],
      (pageParams) =>
        WarehouseItemApi.Search(
          (() => {
            const payload = {
              ...pageParams,
              ...paramsSearch,
              key_search: debouncedValue,
              page_size: limit,
              haveSearchWhenKeyWordEmpty,
              service_tags: "",
            };
            if(warehosue_process_tranfer) payload["warehosue_process_tranfer"] = true
            if (device) payload.device = device;

            return payload;
          })()
        ),
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.page_next;
        },
      }
    );

  return (
    <Grid item xs={size}>
      <Paper sx={{ height: maxHeight, overflow: "scroll", px: 2 }}>
        <Box
          sx={{ position: "sticky", top: 0, backgroundColor: "#fff", py: 2 }}
        >
          <TextField
            size="small"
            placeholder="Tìm kiếm thiết bị..."
            fullWidth
            value={searchQuery.key_search}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon fontSize="small" sx={{ color: "#757575", mr: 1 }} />
              ),
            }}
          />

          <Box display="flex" alignItems="center" justifyContent="center">
            {isLoading && <CircularProgress size={25} sx={{ mt: 2 }} />}
          </Box>
        </Box>

        <Box>
          {!isLoading &&
            data?.pages
              ?.reduce((final, item) => {
                item?.warehouse_items && final.push(...item?.warehouse_items);
                return final;
              }, [])
              ?.map((item) => (
                <Box
                  sx={{ cursor: "pointer" }}
                  key={item?.warehouse_item_id}
                  onClick={() =>
                    onChange
                      ? onChange(item)
                      : navigate(
                          `/warehouse/items/${item?.warehouse_item_id}/detail`
                        )
                  }
                >
                  <Box sx={{ backgroundColor: "#f5f5f5" }} p={1} mb={1}>
                    <Box>
                      <Typography
                        fontSize={13}
                        fontWeight={600}
                        color="#0d47a1"
                      >
                        {item.warehouse_item_name} -{" "}
                        {item.warehouse_item_service_tag}
                      </Typography>
                    </Box>

                    <Typography fontSize={12} fontWeight={600} color="#9e9e9e">
                      {item.warehouse_box_item
                        .warehouse_box_item_reference_type ===
                      "WarehouseBox" ? (
                        <>
                          {
                            _.find(warehouse_boxes, {
                              value:
                                item.warehouse_box_item
                                  .warehouse_box_item_reference_id,
                            }).label
                          }
                        </>
                      ) : (
                        <>
                          {
                            _.find(warehouse_boxes, {
                              value: item.warehouse_rack.warehouse_box_id,
                            }).label
                          }{" "}
                          - {item.warehouse_rack.warehouse_rack_name}- Vị trí:{" "}
                          {item.warehouse_box_item
                            ?.warehouse_box_item_position +
                            (item.warehouse_item_form_factor === 0 ||
                            item.warehouse_item_form_factor === 1
                              ? ""
                              : ` - ${
                                  item.warehouse_box_item
                                    ?.warehouse_box_item_position +
                                  (item.warehouse_item_form_factor - 1)
                                }`)}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}

          <InView
            fallbackInView
            onChange={(InVidew) => {
              if (InVidew && hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
          >
            {({ ref }) => (
              <Box
                ref={ref}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ pb: 1 }}
              >
                {isFetchingNextPage && <CircularProgress size={25} />}
              </Box>
            )}
          </InView>
        </Box>
      </Paper>
    </Grid>
  );
};

export default SearchDevice;
