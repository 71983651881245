import ServiceApi from "@/apis/beta/Service/ServiceApi";
import DataGrid from "@/components/DataGrid";
import { CellLink } from "@/components/DataGrid/Cell";
import DataGridFilter from "@/components/DataGrid/Filter";
import {
    format_date_monent_js,
    format_date_short
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const SERVICE_PAYMENT_TYPES = [
    { value: "postpaid", label: "Đối soát trả sau theo lưu lượng sử dụng" },
    { value: "prevpaid-crosscheck", label: "Mua gói cố định & đối soát vượt" },
];

const Statement = () => {
    const location = useLocation();

    const [query, updateQuery] = useFilters(
        "services.statement.list.filters",
        location
    );

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20,
    });

    const [searchQuery, setSearchQuery] = React.useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    const [initialColumns] = useState([
        {
            field: "tw_services.service_id",
            headerName: "ID",
            width: 60,
            disableReorder: true,
            hideable: true,
            valueGetter: ({ row }) => ({
                label: row?.service_id,
                href: ["services", row?.service_id],
            }),
            operators: { type: "number" },
            renderCell: ({ value }) => <CellLink data={value} target="_blank" />,
        }, {
            field: "tw_services.service_customer_id",
            headerName: "Khách Hàng",
            width: 200,
            valueGetter: ({ row }) => {
                let label = row?.customer_company_name;
                if (!label || label === "") {
                    label = row?.customer_name;
                }
                return {
                    label,
                    href: ["customers", row?.service_customer_id],
                };
            },
            renderCell: ({ value }) => <CellLink data={value} />,
        }, {
            field: "tw_services.service_product_id",
            headerName: "Sản Phẩm",
            width: 200,
            valueGetter: ({ row }) => row?.service_product_name,
        }, {
            field: "abc",
            headerName: "Thông Số Sử Dụng",
            width: 200,
            valueGetter: ({ row }) => row?.service_product_name,
            renderCell: ({ row }) => <MuiSkeleton.TextField />
        }, {
            field: "abcd",
            headerName: "Tạm Tính",
            width: 200,
            valueGetter: ({ row }) => row?.service_product_name,
            renderCell: ({ row }) => <MuiSkeleton.TextField />
        }, {
            field: "service_management_billings.statement_at",
            headerName: "Ngày Phát Sinh H.Đ Đối Soát Kế Tiếp",
            minWidth: 300,
            valueGetter: ({ row }) => row?.service_management_billing?.statement_at,
            valueFormatter: ({ value }) => (!!value ? format_date_short(value) : ""),
            operators: { type: "date", operators: ["$eq"] },
        }, {
            field: "service_management_billings.service_control_enddate",
            headerName: "Chu Kỳ Đối Soát",
            minWidth: 160,
            valueGetter: ({ row }) =>
                `${format_date_monent_js(
                    row?.service_management_billing?.service_control_startdate,
                    "DD/MM"
                )} -
        ${format_date_short(
                    row?.service_management_billing?.service_control_enddate
                )}`,
        },
    ]);

    const historyColumsHidden =
        JSON.parse(localStorage.getItem("services.statement.list.hiddenColumns")) ||
        [];

    const [columns, setColumns] = React.useState(
        initialColumns?.map((column) => {
            if (historyColumsHidden?.includes(column?.field)) {
                return { ...column, hide: true };
            }
            return { ...column };
        }) || initialColumns
    );

    const filterables = columns
        .filter((column) => !!column?.operators && !column?.hide)
        .map((column) => {
            return {
                field: column?.key ? column.key : column.field,
                label: column?.headerName,
                operators: column?.operators,
            };
        });

    const { data, isFetching } = ServiceApi.Statement({
        page: page?.page + 1,
        pageSize: page?.pageSize || 10,
        query: [
            Filter.stringify(query),
            `filters[$and][${Filter.stringify(query)?.split("&")?.length || 1
            }][tw_services.service_referrer_type][$ne]=Reducer::Override::Service`,
            `filters[$and][${(Filter.stringify(query)?.split("&")?.length || 1) + 1
            }][tw_services.service_creatable_type][$in]=StorageLDFService,ConversantService,TranscodingService,RecordingService,TransmuxingService`,
            `filters[$and][${(Filter.stringify(query)?.split("&")?.length || 1) + 1
            }][tw_services.service_status][$eq]=active`,
        ]?.join("&"),
        searchQuery: debouncedSearchQuery || null,
    });

    return (
        <Box>
            <DataGridFilter
                callback={updateQuery}
                filters={{
                    key: "services.statement.list.filters",
                    filterables: filterables,
                }}
            />
            <Box>
                <DataGrid
                    rows={
                        data?.services?.map((service) => ({
                            ...service,
                            id: service?.service_id,
                        })) || []
                    }
                    columns={columns}
                    loading={isFetching}
                    componentsProps={{
                        toolbar: {
                            store: "services.statement.list.hiddenColumns",
                            columns: columns?.filter((col) => !col?.hideable),
                            setColumns: setColumns,
                            initialColumns: initialColumns,
                            inputSearch: {
                                placeholder: "Nhập ID dịch vụ/ ID khách hàng tìm kiếm...",
                                value: searchQuery,
                                onChange: setSearchQuery,
                            },
                        },
                        pagination: {
                            rowsPerPageOptions: [10, 20, 50, 100],
                            page: page?.page || 0,
                            pageSize: page?.pageSize,
                            onPageChange: (newPage) =>
                                setPage((prevState) => ({ ...prevState, page: newPage })),
                            onPageSizeChange: (newPageSize) =>
                                setPage((prevState) => ({
                                    ...prevState,
                                    pageSize: newPageSize,
                                })),
                        },
                    }}
                    getRowHeight={({ model }) => {
                        const PADDING_OFFSET_Y = 8;
                        const COUNT_COMMITMENT_LINES =
                            24 * (model?.commitments?.length || 0) + PADDING_OFFSET_Y;
                        return Math.max(COUNT_COMMITMENT_LINES, 52);
                    }}
                    rowCount={data?.pagination?.total}
                />
            </Box>
        </Box>
    );
};
export default withSuspense(
    withPermission(Statement, { feature: "invoice", action: "statement" }),
    MuiSkeleton["DataGrid"]
);
