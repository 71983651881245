import { format_date_short, isObjEmpty } from '@/functions'
import { INITIALIZE_SERVICE_GROUPS, INVOICE_STATUS_TYPES } from '@/libs/constants'
import DatePicker from '@components/DatePicker'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { FormControl, Grid } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'

const BASE = {
    MultipleSelectCustomize: ({ value: values = [], onChange, options = [], ...props }) => {
        const selecteds = options?.filter(option => values?.includes(option?.value)) || []
        return <MultipleSelectCustomize
            value={selecteds}
            options={options}
            onChange={(__, newValues) => onChange(newValues?.map(option => option?.value) || [])}
            {...props}
        />
    },
    DatePicker: ({ value, onChange, ...props }) => {
        return (
            <DatePicker value={value} onChange={onChange} {...props} />
        )
    }
}

const InvoiceFilter = ({
    updateQuery,
    callback,
}) => {

    const [state, setState] = useState([
        {
            key: 'invoices.invoice_status',
            operator: '$in',
            type: 'number',
            component: 'MultipleSelectCustomize',
            options: INVOICE_STATUS_TYPES,
            value: [],
            onChange: (newValues) => setState(preState => [
                {
                    ...preState[0],
                    value: newValues
                },
                ...preState?.slice(1)]),
            params: {
                input: {
                    label: 'Trạng thái hóa đơn',
                    placeholder: 'Chọn trạng thái...'
                }
            }
        }, {
            key: 'tw_services.service_product_id',
            operator: '$in',
            type: 'number',
            component: 'MultipleSelectCustomize',
            options: INITIALIZE_SERVICE_GROUPS,
            value: [],
            onChange: (newValues) => setState(preState => [
                ...preState?.slice(0, 1),
                {
                    ...preState[1],
                    value: newValues
                },
                ...preState?.slice(2)]),
            params: {
                input: {
                    label: 'Nhóm Dịch Vụ',
                    placeholder: 'Chọn nhóm dịch vụ...'
                }
            }
        }, {
            key: 'invoice_apply_startdate',
            operator: '$gte',
            type: 'date',
            component: "DatePicker",
            value: null,
            onChange: (newValue) => setState(preState => [
                ...preState?.slice(0, 2),
                {
                    ...preState[2],
                    value: newValue
                },
                ...preState?.slice(3),
            ]),
            params: {
                label: 'Thời Gian Bắt Đầu Tính Phí Dịch Vụ'
            }
        }, {
            key: 'invoice_apply_duedate',
            operator: '$lte',
            type: 'date',
            component: "DatePicker",
            value: null,
            onChange: (newValue) => setState(preState => [
                ...preState?.slice(0, 3),
                {
                    ...preState[3],
                    value: newValue
                }]),
            params: {
                label: 'Thời Gian Kết Thúc Tính Phí Dịch Vụ'
            }
        }
    ])

    useEffect(() => {
        let andConditions = []
        _.forEach(state, (obj) => {
            let hash = {}
            let hashObj = {}

            if (obj.type === 'date' && !!obj.value) {
                hashObj[obj.operator] = format_date_short(obj?.value?.$d) || format_date_short(obj?.value)
                hash[obj.key] = hashObj;
            }
            if (obj.type === 'number' && !!obj.value?.length) {
                hashObj[obj.operator] = obj?.value?.join(',')
                hash[obj.key] = hashObj;
            }
            if (!isObjEmpty(hash)) {
                andConditions.push(hash)
            }
        })

        console.log(`andConditions`, andConditions)
        const filters = {
            $and: andConditions
        }

        updateQuery(filters)
        callback(filters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {state?.map((column, index) => {
                if (column?.hide) return null;

                const Component = BASE[column?.component]
                if (!Component) return null;

                return (
                    <Grid item xs={12} md={4} xl={3} key={index}>
                        <FormControl fullWidth>
                            <Component
                                value={column.value}
                                onChange={column?.onChange}
                                options={[...column?.options || []]}
                                {...column?.params || {}}
                            />
                        </FormControl>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default InvoiceFilter