import ProductApi from "@/apis/beta/Product/ProductApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { sortPriorities } from "@features/Module/Setting/Product/Cart/ProductOne";
import { Box, FormControl, LinearProgress } from "@mui/material";
import React, { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";

const PRIORITIES = [
	"server",
	"cpu",
	"disk",
	"storage",
	"ram",
	"memory",
	"ip",
	"software",
	"datacenter",
	"os",
	"other",
	"default",
];

const Product = ({ disabled = true, product, setProduct }) => {

	console.log(product)
	const [state, setState] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const debouncedSearchQuery = useDebounce(state, 500);

	const {
		control,
		setValue,
		formState: { errors },
		watch,
	} = useFormContext();

	const filterableProductGroup = React.useCallback(() => {
		const fieldQueries = [
			{
				name: "product_group_id",
				operator: "$eq",
				value: watch(`product_group_id`),
			},
			{
				name: "product_type",
				operator: "$notContains",
				value: "addon",
			},
			{
				name: "product_hidden",
				operator: "$eq",
				value: 0,
			},
		];
		return buildQueryFilterCondition("$and", 0, fieldQueries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch(`product_group_id`)]);

	const filterableProduct = React.useCallback(() => {
		if (!debouncedSearchQuery || debouncedSearchQuery.trim() === "")
			return null;
		const fieldQueries = [
			{
				name: "product_name",
				operator: "$contains",
				value: debouncedSearchQuery,
			},
		];
		return buildQueryFilterCondition("$or", 0, fieldQueries);
	}, [debouncedSearchQuery]);

	const {
		data: { products },
		isFetching,
	} = ProductApi.List({
		query: filterableProductGroup(),
		searchQuery: filterableProduct(),
	});

	React.useEffect(() => {
		if (products && product?.length !== 0 && watch(`product_id`)) {
			const p = products?.find(
				(item) => item?.product_id === parseInt(watch(`product_id`))
			);
			try {
				setProduct((prev) => ({
					...p,
					product_group_id: p?.product_group?.product_group_id,
					product_group_name: p?.product_group?.product_group_name,
				}));
			} catch (error) {
				console.log(error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch(`product_id`)]);

	const rebuildProductConfigoptions = (productConfigoptions = []) => {
		return productConfigoptions?.map((productConfigoption) => {
			return {
				...productConfigoption,
				method: "progressive",
				product_pricing_steps: productConfigoption?.product_pricing_steps?.map(
					(item) => {
						return {
							...item,
							pricing_apply: item?.pricing || 0,
						};
					}
				),
			};
		});
	};

	const rebuildProductPricingConfigures = (productPricingConfigure = {}) => {
		const data = {
			pricing: productPricingConfigure?.pricing,
			method: "progressive",
			value: 0,
			pricing_apply: productPricingConfigure?.pricing,
			product_pricing_unit:
				productPricingConfigure?.product_pricing_unit || "qty",
			product_pricing_steps:
				productPricingConfigure?.product_pricing_steps?.map((item) => {
					return { ...item, pricing_apply: item.pricing };
				}),
		};
		return data;
	};

	const handleOnchange = async (newValue) => {
		if (newValue?.product_id <= 0) return;
		setLoading(true);
		const { product: data } = await ProductApi.GetProduct({ id: newValue?.product_id });
		if (newValue?.value === -1) {
			setProduct((prev) => ({
				product_group_id: prev?.product_group_id,
				product_group_name: prev?.product_group_name,
			}));
		} else {
			setProduct((prev) => ({ ...prev, ...data }));
		}
		console.log(data)
		try {
			setValue(`product_payment_type`, "prevpaid-crosscheck");
			setValue("product_type", data?.product_type);
			setValue(`product_name`, data?.product_name || "");
			setValue(`product_customizable`, data?.product_customizable || false);
			setValue(`product_template`, data?.product_template || "Blank");
			setValue(`product_informations`, data?.product_informations || []);
			setValue(
				`product_configoptions`,
				rebuildProductConfigoptions(data?.product_configoptions)
			);
			setValue(
				"product_pricing_configures",
				rebuildProductPricingConfigures(data?.product_pricing_configures)
			);

			setValue(`product_pricing_minimum`, data?.pricing?.pricing_minimum || 0);
			setValue(`product_pricing_amount`, data?.pricing?.pricing || 0);
			setValue(`product_pricing_init`, data?.pricing?.pricing || 0);
			setValue(`product_addon_mergebill`, data?.product_customizable);
			if (!!data?.product_customizable) {
				processBuildCustomizable(data?.product_addons);
			}
		} catch (error) {
			console.log(error?.message);
		}
		return setLoading(false);
	};

	const processBuildCustomizable = (options) => {
		const product_addons = sortPriorities(options, PRIORITIES, "product_type");

		setValue(
			`product_addons`,
			product_addons
				?.filter((x) => x?.product_addon_enabled)
				?.map((option) => {
					return {
						enabled: true,
						product_id: option?.product_addon_id,
						product_name: option?.product_name,
						product_type: option?.product_type,
						statemented: !!option?.statemented,
						qty: 1,
						description: "",
						unit: option?.product_unit,
						pricing: option?.pricing?.pricing,
						pricing_apply: option?.pricing?.pricing,
						pricing_minimum: option?.pricing?.pricing_minimum,
						product_pricing_unit: option?.product_unit,
						product_addon_range_enabled: option?.product_addon_range_enabled,
						price_range_enabled: false,
						price_range_steps: [
							{
								start: 0,
								end: 999999999,
								pricing: option?.pricing?.pricing,
								pricing_apply: option?.pricing?.pricing,
								pricing_minimum: option?.pricing?.pricing_minimum,
							},
						],
					};
				})
		);
	};

	return (
		<Fragment>
			<Box sx={{ width: "100%", position: "relative" }}>
				{loading && (
					<LinearProgress
						sx={{
							width: "100%",
							position: "absolute",
							top: "-12px",
							height: "2px",
						}}
					/>
				)}
				<Controller
					name="product_id"
					control={control}
					render={({ field: { value, onChange } }) => {
						return (
							<FormControl fullWidth>
								<SingleSelectCustomize
									loading={isFetching}
									disabled={
										loading ||
										!watch(`product_group_id`) ||
										watch(`product_group_id`) === -1 ||
										disabled
									}
									value={
										products?.find((item) => item?.product_id === value) || null
									}
									options={products}
									onChange={(event, newValue) => {
										onChange(newValue?.product_id || -1);
										handleOnchange(newValue);
									}}
									compareAttributes={["product_id", "product_name"]}
									formatCollection={{
										value: "product_id",
										label: "product_name",
									}}
									input={{
										label: "Dịch vụ *",
										placeholder: "Chọn sản phẩm/ dịch vụ...",
										value: state,
										onChange: (e) => setState(e?.target?.value || ""),
									}}
								/>
								{!!errors?.product_id && (
									<ErrorHelperText message={errors?.product_id?.message} />
								)}
							</FormControl>
						);
					}}
				/>
			</Box>
		</Fragment>
	);
};

export default withSuspense(Product, MuiSkeleton["TextField"]);
