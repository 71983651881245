import ContactApi from "@/apis/beta/Customer/ContactApi";
import { buildQueryFilterCondition, format_date_time } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useFilters, { Filter } from "@/hooks/useFilters";
import {
  CONTACT_CHANNEL,
  CONTACT_STATUS_TYPES,
  SERVICES,
} from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import DataGridFilter from "@components/DataGrid/Filter";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Collapse, Drawer } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Destroy from "../Destroy";
import Detail from "../Detail";
import Actions from "./Actions";
import ContactHeader from "./Header";
import Statistic from "./Statistic";
import ContactChannel from "./shared/ContactChannel";
import ContactExplaination from "./shared/ContactExplaination";
import ContactOnClickOpen from "./shared/ContactOpen";
import ContactProfile from "./shared/ContactProfile";
import ContactRevenue from "./shared/ContactRevenue";
import ContactStatus from "./shared/ContactStatus";
import useDebounce from "@/hooks/useDebounce";

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters(
    `contact-source-marketings.list.filters`,
    location
  );

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 400)

  const filterableContactSource = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "contact_source_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contact_source_email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contact_source_company",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contact_source_website",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
  }, [debouncedSearchQuery]);

  const [viewable, setViewable] = useState({
    open: false,
    data: undefined,
  });
  const initialColumns = [
    {
      field: "contact_source_name",
      headerName: "Tên",
      width: 230,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_position",
      headerName: "Chức Vụ",
      width: 180,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_phone",
      headerName: "Số Điện Thoại",
      width: 150,
      operators: { type: "string", operators: ["$contains"] },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_email",
      headerName: "Email",
      width: 250,
      operators: { type: "string", operators: ["$contains"] },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_profile",
      headerName: "Profile (Hồ Sơ MXH) ",
      width: 200,
      operators: { type: "string" },
      renderCell: ({ value }) => <ContactProfile value={value} />,
    },
    {
      field: "contact_source_company",
      headerName: "Công Ty",
      width: 200,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_industry",
      headerName: "Ngành",
      width: 200,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_website",
      headerName: "Website",
      width: 150,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_notes",
      headerName: "Ghi Chú",
      minWidth: 150,
      operators: { type: "string" },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_channel",
      headerName: "Kênh Liên Hệ",
      width: 160,
      renderCell: ({ value }) => <ContactChannel value={value} />,
      operators: { type: "object", collections: CONTACT_CHANNEL },
    },
    {
      field: "contact_source_interested_services",
      headerName: "Giải Pháp",
      width: 150,
      operators: { type: "object", collections: SERVICES, operators: ['$included'] },
      renderCell: ({ value, row }) => (
        <ContactOnClickOpen
          value={value}
          onClick={() => setViewable({ open: true, data: row })}
        />
      ),
    },
    {
      field: "contact_source_status",
      headerName: "Phân Loại Khách Hàng",
      width: 180,
      operators: {
        type: "object",
        collections: CONTACT_STATUS_TYPES,
        operators: ['$in', '$eq', '$nin', '$ne']
      },
      renderCell: ({ value }) => <ContactStatus value={value} />,
    },
    {
      field: "contact_source_customer_journey_status",
      headerName: "Trạng Thái",
      width: 200,
      renderCell: ({ row }) => <ContactExplaination data={row} />,
    },
    {
      field: "contact_source_assignee",
      headerName: "Người Phụ Trách",
      width: 200,
      valueGetter: ({ row }) => {
        if (!row?.contact_source_assignee?.employee_name) return ``;
        return row?.contact_source_assignee?.employee_name || "";
      },
    },
    {
      field: "contact_source_revenue",
      headerName: "Doanh Thu",
      width: 200,
      renderCell: ({ row }) => <ContactRevenue data={row} />,
    },
    {
      field: "contact_sources.contact_source_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      valueGetter: ({ row }) =>
        format_date_time(row?.contact_source_created_at, true),
      operators: { type: "date" },
    },
    {
      field: "actions",
      headerName: "",
      hideable: true,
      width: 50,
      renderCell: ({ row }) => <Actions data={row} />,
    },
  ];
  const historyColumsHidden =
    JSON.parse(
      localStorage.getItem("contact-source-marketings.list.hiddenColumns")
    ) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isFetching } = ContactApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query: [
      Filter.stringify(query),
      `filters[$and][${Filter.stringify(query)?.split("&")?.length || 1
      }][contact_sources.contact_source_status][$ne]=HR`,
    ]?.join("&"),
    searchQuery: filterableContactSource()
  });
  const [buttonConfirm, setButtonConfirm] = React.useState({
    open: false,
    contactSourceIds: [],
  });
  return (
    <Box>
      <ContactHeader />
      <Statistic query={Filter.stringify(query)} />
      {viewable?.open && !!viewable?.data && (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => setViewable({ open: false, data: undefined })}
          sx={{
            width: { xs: "100%", md: 300 },
            borderTopLeftRadius: "4px",
          }}
        >
          <Detail
            contactId={viewable?.data?.contact_source_id}
            onClose={() => setViewable({ open: false, data: undefined })}
          />
        </Drawer>
      )}
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "contact-source-marketings.list.filters",
          filterables: filterables,
          exportFeature: "Contact",
        }}
      />
      <Collapse in={buttonConfirm?.contactSourceIds?.length !== 0}>
        <Destroy contactIds={buttonConfirm?.contactSourceIds} />
      </Collapse>
      <DataGridCustomize
        rows={
          data?.contacts?.map((contact) => ({
            ...contact,
            id: contact?.contact_source_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "contact-source-marketings.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "contact",
            inputSearch: {
              placeholder: "Nhập tên khách hàng/ email/ tên công ty tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery
            }
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        rowCount={data?.pagination?.total}
        initialState={{
          pinnedColumns: { right: ["actions"] },
        }}
        isRowSelectable={({ row }) => {
          if (row?.contact_source_status === "MATCHED") return false;
          return true;
        }}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={(props) => {
          setButtonConfirm((prevState) => ({
            ...prevState,
            contactSourceIds: props,
          }));
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "contact",
    action: "index",
  }),
  MuiSkeleton["DataGrid"]
);
